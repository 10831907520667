import * as React from 'react'

import { graphql, useStaticQuery } from 'gatsby'
import SEO from '../../components/SEO'


const siteQuery = graphql`
  {
    allSite {
      edges {
        node {
          siteMetadata {
            name
            siteUrl
          }
        }
      }
    }
  }
`

interface ArticleSEOProps {
  article: any
  specialists: any[]
  location: Location
}

const ArticleSEO: React.FC<ArticleSEOProps> = ({
  article,
  specialists,
  location
}) => {
  const results = useStaticQuery(siteQuery)
  const { name } = results.allSite.edges[0].node.siteMetadata
  const { siteUrl } = results.allSite.edges[0].node.siteMetadata

  const specialistsData = specialists.map((specialist) => ({
    '@type': 'Person',
    name: specialist.name
  }))

  /**
   * For some reason `location.href` is undefined here when using `yarn build`.
   * That is why I am using static query `allSite` to get needed fields: name & siteUrl.
   */
  const microdata = `{
    "@context": "https://schema.org",
    "@type": "Article",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "${siteUrl + location.pathname}"
    },
    "headline": "${article.title}",
    "image": "${siteUrl + article.hero.seo.src}",
    "datePublished": "${article.dateForSEO}",
    "dateModified": "${article.dateForSEO}",
    "": ${JSON.stringify(specialistsData)},
    "description": "${article.excerpt.replace(/"/g, '\\"')}",
    "publisher": {
      "@type": "Organization",
      "name": "${name}",
      "logo": {
        "@type": "ImageObject",
        "url": "${siteUrl}/icons/icon-512x512.png"
      }
    }
  }
`.replace(/"[^"]+"|(\s)/gm, (matched, group1) => {
    if (!group1) {
      return matched
    }
    return ''
  })
  /**
   * See here for the explanation of the regex above:
   * https://stackoverflow.com/a/23667311
   */

  return (
    <SEO
      title={`${name} - ${article.title}`}
      description={article.excerpt}
      image={article.hero.seo.src}
      timeToRead={article.timeToRead.toString()}
      published={article.date}
      pathname={location.href}
      canonicalUrl={article.canonical_url}
    >
      <script type="application/ld+json">{microdata}</script>
    </SEO>
  )
}

export default ArticleSEO

import React, { useState } from 'react'
import styled from 'styled-components'
import OutsideClickHandler from 'react-outside-click-handler'
import { useColorMode } from '@bestyled/contrib-use-color-mode'
import { Link } from 'gatsby'
import mediaqueries from '../../styles/media'

import Icons from '../../components/TComponents/icons'
import { useI18n } from '@bestyled/primitives'
import Image from '../../components/Image'


/**
 * When generating the  names we're also checking to see how long the
 * number of specialists are. If it's only 2 specialists we'll show the fullnames.
 * Otherwise it'll only preview the first names of each .
 */
function generateSpecialistNames(specialists: any[]) {
  return specialists
    .map((specialist) => {
      if (specialists.length > 2) {
        return specialist.name.split(' ')[0]
      }
      return specialist.name
    })
    .join(', ')
}

interface SpecialistsProps {
  specialists: any[]
  hideComma?: boolean
}

const CoSpecialists: React.FC<SpecialistsProps> = ({ specialists }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [colorMode] = useColorMode()
  const names = generateSpecialistNames(specialists)

  const fill = colorMode === 'dark' ? '#fff' : '#000'
  const listWidth = { width: `${10 + specialists.length * 15}px` }
  const { getLangPath } = useI18n()

  return (
    <CoSpecialistsContainer onClick={() => setIsOpen(!isOpen)} isOpen={isOpen}>
      <CoSpecialistsList style={listWidth}>
        {specialists.map((specialist, index) => (
          <CoSpecialistAvatar
            style={{ left: `${index * 15}px` }}
            key={specialist.name}
          >
            <RoundedImage
              src={{ ...specialist.avatar.medium, aspectRatio: 1 }}
            />
          </CoSpecialistAvatar>
        ))}
      </CoSpecialistsList>
      <NameContainer>{names}</NameContainer>
      <IconContainer>
        <Icons.ToggleOpen fill={fill} />
      </IconContainer>

      {isOpen && (
        <OutsideClickHandler onOutsideClick={() => setIsOpen(!isOpen)}>
          <CoSpecialistsListOpen>
            <IconOpenContainer>
              <Icons.ToggleClose fill={fill} />
            </IconOpenContainer>
            {specialists.map((specialist) => (
              <CoSpecialistsListItemOpen key={specialist.name}>
                <SpecialistLink to={getLangPath(specialist.slug)}>
                  <CoSpecialistAvatarOpen>
                    <RoundedImage
                      src={{ ...specialist.avatar.medium, aspectRatio: 1 }}
                    />
                  </CoSpecialistAvatarOpen>
                  <SpecialistNameOpen>{specialist.name}</SpecialistNameOpen>
                </SpecialistLink>
              </CoSpecialistsListItemOpen>
            ))}
          </CoSpecialistsListOpen>
        </OutsideClickHandler>
      )}
    </CoSpecialistsContainer>
  )
}

/**
 * Slipway supports multiple specialists per article and therefore we need to ensure
 * we render the right UI when there are varying amount of specialists.
 */
const ArticleSpecialists: React.FC<SpecialistsProps> = ({
  specialists,
  hideComma
}) => {
  const hasCoSpecialists = specialists.length > 1

  // Special dropdown UI for multiple specialists
  if (hasCoSpecialists) {
    return <CoSpecialists specialists={specialists} />
  }
  return (
    <SpecialistLink to={specialists[0].slug}>
      <SpecialistAvatar>
        <RoundedImage src={specialists[0].avatar.small} />
      </SpecialistAvatar>
      <strong>{specialists[0].name}</strong>
      <HideOnMobile>{hideComma ? '' : ','}&nbsp;</HideOnMobile>
    </SpecialistLink>
  )
}

export default ArticleSpecialists

const SpecialistAvatar = styled.div`
  height: 25px;
  width: 25px;
  border-radius: 50%;
  margin-right: 14px;
  background: ${(p) => p.theme.colors.grey};
  overflow: hidden;

  .gatsby-image-wrapper > div {
    padding-bottom: 100% !important;
  }

  ${mediaqueries.phablet`
    display: none;
  `}
`

const RoundedImage = styled(Image)`
  border-radius: 50%;
`

const SpecialistLink = styled(Link)`
  display: flex;
  align-items: center;
  color: inherit;

  strong {
    transition: ${(p) => p.theme.colorModeTransition};
  }

  &:hover strong {
    color: ${(p) => p.theme.colors.primary};
  }
`

const CoSpecialistsList = styled.div`
  position: relative;
  height: 25px;
  margin-right: 15px;

  ${mediaqueries.phablet`
    display: none;
  `}
`

const CoSpecialistsListOpen = styled.ul`
  position: absolute;
  z-index: 2;
  left: -21px;
  right: -21px;
  top: -19px;
  padding: 21px;
  background: ${(p) => p.theme.colors.card};
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  cursor: pointer;
  list-style: none;
  transform: translateY(-2px);
`

const CoSpecialistsListItemOpen = styled.li`
  a {
    width: 100%;
  }

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`

const CoSpecialistAvatarOpen = styled.div`
  height: 25px;
  width: 25px;
  border-radius: 50%;
  margin-right: 15px;
  background: ${(p) => p.theme.colors.grey};
  overflow: hidden;
  pointer-events: none;

  .gatsby-image-wrapper > div {
    padding-bottom: 100% !important;
    overflow: hidden;
  }
`

const CoSpecialistAvatar = styled.div`
  position: absolute;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  z-index: 1;
  background: ${(p) => p.theme.colors.grey};
  box-shadow: 0 0 0 2px ${(p) => p.theme.colors.background};
  transition: box-shadow 0.25s ease;
  overflow: hidden;
  pointer-events: none;

  .gatsby-image-wrapper > div {
    padding-bottom: 100% !important;
    overflow: hidden;
  }

  ${mediaqueries.phablet`
    display: none;
  `}
`

const NameContainer = styled.strong`
  position: relative;
  max-width: 260px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  cursor: pointer;

  ${mediaqueries.desktop`
    max-width: 120px;
  `}

  ${mediaqueries.phablet`
    max-width: 200px;
  `}
`

const SpecialistNameOpen = styled.strong`
  position: relative;
  cursor: pointer;
  color: ${(p) => p.theme.colors.secondary};
  font-weight: 600;
`

const IconContainer = styled.div`
  position: relative;
  cursor: pointer;
  margin-left: 10px;

  ${mediaqueries.phablet`
    position: absolute;
    right: 0;
    bottom: 0;
    top: 10px;
    height: 100%;
  `}
`

const IconOpenContainer = styled.div`
  position: absolute;
  cursor: pointer;
  top: 20px;
  right: 21px;
`

const CoSpecialistsContainer = styled.div<{ isOpen: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  font-size: 18px;
  color: ${(p) => p.theme.colors.grey};
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    left: -20px;
    right: -20px;
    top: -16px;
    bottom: -16px;
    background: ${(p) => p.theme.colors.card};
    box-shadow: ${(p) =>
      p.isOpen ? 'none' : ' 0px 0px 15px rgba(0, 0, 0, 0.1)'};
    border-radius: 5px;
    z-index: 0;
    transition: opacity 0.3s;
    cursor: pointer;
    opacity: 0;
  }

  &:hover::before {
    opacity: 1;
  }

  ${mediaqueries.phablet`
    font-size: 14px;
    align-items: center;

    &::before {
      box-shadow: none;
      bottom: -30px;
      background: transparent;
    }


    strong {
      display: block;
      font-weight: semi-bold;
      margin-bottom: 5px;
    }
  `}
`

const HideOnMobile = styled.span`
  ${mediaqueries.phablet`
    display: none;
  `}
`
